import React, { useRef } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import WorkIcon from '@mui/icons-material/Work';
import LinkIcon from '@mui/icons-material/Link'; // Or another icon that fits "broker-connect"

const FooterContainer = styled.div`
  background-color: #2d3748;
  padding: 16px;
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-around;
  font-size: 0.75rem;
  color: #a0aec0;
`;
const IconButton = styled.button`
  background: none;
  border: none;
  outline: none;
  box-shadow: none;
  color: #CBD5E0; /* Light text color */
  padding: 8px; /* Slight padding around the icon */
  border-radius: 0; /* Removes any default border-radius */
  -webkit-appearance: none; /* Removes default browser styling on iOS */
  -moz-appearance: none; /* Removes default browser styling on Firefox */
  appearance: none; /* Removes default browser styling */

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: color 0.3s, background-color 0.3s;

  &:hover {
    color: #ffffff;
    background-color: #2d3748; /* Slight background color on hover */
  }

  &:active {
    background-color: #4a5568; /* Slightly darker background color on active/click */
  }

  &:focus-visible {
    outline: 2px solid #CBD5E0; /* Only show an outline when the element is focused by keyboard navigation */
  }

  &:last-child {
    margin-right: 0;
  }
`;

const Footer = () => {

  const navbarToggler = useRef<HTMLButtonElement>(null);

  const handleNavLinkClick = () => {
      if (navbarToggler.current) {
          navbarToggler.current.click();
      }
  };

  return (
    <FooterContainer>
      <Link to="/transactions" className="nav-link text-warning" onClick={handleNavLinkClick}>
        <IconButton>
          <AccountBalanceWalletIcon />
          <span>Transactions</span>
        </IconButton>
      </Link>
      <Link to="/portfolio" className="nav-link text-warning" onClick={handleNavLinkClick}>
        <IconButton>
          <WorkIcon />
          <span>Portfolio</span>
        </IconButton>
      </Link>
      <Link to="/broker-connect" className="nav-link text-warning" onClick={handleNavLinkClick}>
        <IconButton>
          <LinkIcon />
          <span>Broker-Connect</span>
        </IconButton>
      </Link>
    </FooterContainer>
  );
};
export default Footer;
