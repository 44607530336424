import styled from 'styled-components';

export const Container = styled.div`
  flex-grow: 1;
  background-color: #1A202C; /* Dark background */
  color: #CBD5E0; /* Light text color */
  font-family: 'Roboto', sans-serif;
  overflow-y: auto; /* Scroll inside container if content overflows */
`;

export const Header = styled.div`
  padding: 16px;
  background-color: #2d3748; /* Tailwind gray-800 */
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.h1`
  font-size: 1.125rem; /* Tailwind text-lg */
  font-weight: bold;
`;

export const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
`;

export const Button = styled.button`
  color: #CBD5E0; /* Light text color */
  margin-right: 16px; /* Tailwind mr-4 */
  &:last-child {
    margin-right: 0;
  }
`;

export const TabButton = styled(Button)`
  background: transparent; /* No background */
  border: none; /* No border */
  box-shadow: none; /* No shadow */
  font-weight: bold;
  padding: 8px 16px; /* Padding around the text */
  margin-right: 8px; /* Space between tabs */
  border-radius: 4px; /* Optional: Slight rounding of corners */
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: #2d3748; /* Slight background on hover */
    color: #ffffff;
  }

  ${props => props.active && `
    color: #ffffff; /* Active tab text color */
    border-bottom: 2px solid #CBD5E0;
    background-color: #4a5568; /* Different background for active tab */
  `}

  &:last-child {
    margin-right: 0;
  }
`;


export const Content = styled.div`
  flex-grow: 1;
  padding: 16px;
  background-color: #1a202c; /* Tailwind gray-900 */
`;

export const Card = styled.div`
  background-color: #2d3748; /* Tailwind gray-800 */
  padding: 16px;
  /* border-radius: 8px;  */
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
`;

export const Footer = styled.div`
  background-color: #2d3748; /* Tailwind gray-800 */
  padding: 16px;
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-around;
  font-size: 0.75rem; /* Tailwind text-xs */
  color: #a0aec0; /* Tailwind text-gray-400 */
`;
export const FlexRow = styled.div`
  display: flex;
  align-items: center;
`;

export const FlexColumn = styled(FlexRow)`
  flex-direction: column;
  align-items: center;
`;


export const FlexRowBetween = styled(FlexRow)`
  justify-content: space-between;
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border-bottom: 1px solid #4a5568;  */
  padding-bottom: 16px;
`;

export const CardContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 16px; /* Tailwind pt-4 */
`;

export const TextSmall = styled.div`
  font-size: 0.875rem; /* Tailwind text-sm */
  font-weight: bold;
`;

export const TextSmallRed = styled(TextSmall)`
  color: #f56565; /* Tailwind text-red-600 */
`;
export const IconButton = styled.button`
  background: none;
  border: none;
  outline: none;
  box-shadow: none;
  color: #CBD5E0; /* Light text color */
  padding: 8px; /* Slight padding around the icon */
  border-radius: 0; /* Removes any default border-radius */
  -webkit-appearance: none; /* Removes default browser styling on iOS */
  -moz-appearance: none; /* Removes default browser styling on Firefox */
  appearance: none; /* Removes default browser styling */

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: color 0.3s, background-color 0.3s;

  &:hover {
    color: #ffffff;
    background-color: #2d3748; /* Slight background color on hover */
  }

  &:active {
    background-color: #4a5568; /* Slightly darker background color on active/click */
  }

  &:focus-visible {
    outline: 2px solid #CBD5E0; /* Only show an outline when the element is focused by keyboard navigation */
  }

  &:last-child {
    margin-right: 0;
  }
`;






//////////

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr); // Adjust the number of columns as needed
  gap: 16px; // Adjust the gap between grid items
`;

// Define a grid item
export const GridItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 16px;
  background-color: #2d3748; /* Adjust the background color */
  border-radius: 8px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
`;


export const StyledTextSmall = styled(TextSmall)`
  color: ${props => props.isPositive ? 'green' : 'red'};
`;