import React, { useEffect, useContext, useState } from 'react';
import './App.css';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ProtectedWrapper from './ProtectedWrapper';
import OrdersPage from './components/orders page/OrdersPage';
import PortfolioPage from './components/PortfolioPage/PortfolioPage';
import BrokerConnectPage from './components/BrokerConnectPage/BrokerConnectPage';
import { useNavigate, useLocation } from 'react-router-dom';

// Import the Context and Health Check Service
import { ConnectionStatusProvider, ConnectionStatusContext } from './Context/ConnectionStatusContext.js';
import { checkHealth } from './services/HealthCheckService.js';

function AppContent() {
    const { status,setStatus } = useContext(ConnectionStatusContext);
    const navigate = useNavigate();
    const location = useLocation();
    const [intervalDuration, setIntervalDuration] = useState(5000); // 5 seconds as initial value

    useEffect(() => {
        // Update the interval duration based on status
        if (status === 'Connected') {
            setIntervalDuration(300000); // 5 minutes
        } else {
            setIntervalDuration(10000); // 5 seconds
        }
    }, [status]);

    useEffect(() => {
        // Perform initial health check
        checkHealth(setStatus);

        // Set up an interval for periodic health checks
        const intervalId = setInterval(() => {
            checkHealth(setStatus);
        }, intervalDuration); 

        // Cleanup function to clear the interval
        return () => {
            clearInterval(intervalId);
        };
    }, [setStatus,intervalDuration]);


    useEffect(() => {
        // Redirect to '/broker-connect' if status is not 'healthy' and not on that page
        if (status !== 'Connected' && location.pathname !== '/broker-connect') {
            navigate('/broker-connect');
        }
    }, [status, location.pathname, navigate]);


    return (
        <div className="App">
            <Header />
            <Routes>
                <Route path="/transactions" element={
                    <ProtectedWrapper>
                        <OrdersPage />
                    </ProtectedWrapper>
                } />
                <Route path="/portfolio" element={
                    <ProtectedWrapper>
                        <PortfolioPage />
                    </ProtectedWrapper>
                } />
                <Route path="/broker-connect" element={
                    <ProtectedWrapper>
                        <BrokerConnectPage />
                    </ProtectedWrapper>
                } />
                <Route path="*" element={
                    <ProtectedWrapper>
                        <PortfolioPage /> {/* Default fallback */}
                    </ProtectedWrapper>
                } />
            </Routes>
            <Footer />
        </div>
    );
}

function App() {
    return (
        <Router>
            <ConnectionStatusProvider>
                <AppContent />
            </ConnectionStatusProvider>
        </Router>
    );
}

export default App;
