import axios from 'axios';
import keycloak from '../keycloak/keycloakService';

const baseURL = process.env.REACT_APP_API_URL || 'http://52.19.95.24:80';

const api = axios.create({
  baseURL: baseURL,
});
api.interceptors.request.use(
  async (config) => {
    // Refresh token if it's expired
    if (keycloak.isTokenExpired()) {
      await keycloak.updateToken(30);
    }

    const token = keycloak.token;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Fetching balances
export const fetchBalances = async () => {
  try {
    const response = await api.get('/balances');
    console.log("Balances:", response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching balances:', error);
    throw error;
  }
};

// Fetching portfolio
export const fetchPortfolio = async () => {
  try {
    const response = await api.get('/portfolios');
    console.log("Portfolio:", response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching portfolio:', error);
    throw error;
  }
};

// You can add more functions for other portfolio related operations (like update) as needed.
// Fetching portfolio
export const fetchPositions = async () => {
  try {
    const response = await api.get('/positions');
    console.log("positions:", response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching portfolio:', error);
    throw error;
  }
};


export const fetchOrders = async () => {
  try {
    const response = await api.get('/positions/trades');
    console.log("trades:", response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching portfolio:', error);
    throw error;
  }
};