// ConnectionStatusContext.js

import React, { createContext, useState } from 'react';

export const ConnectionStatusContext = createContext();

export const ConnectionStatusProvider = ({ children }) => {
  const [status, setStatus] = useState(null);

  return (
    <ConnectionStatusContext.Provider value={{ status, setStatus }}>
      {children}
    </ConnectionStatusContext.Provider>
  );
};
