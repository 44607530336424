
// index.tsx
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { initKeycloak } from './services/keycloak/keycloakService';
import { AuthProvider } from './AuthContext';

initKeycloak().then((authenticated) => {
  if (authenticated) {
    ReactDOM.render(
      <React.StrictMode>
        <AuthProvider> {/* Wrap your App component with AuthProvider */}
          <App />
        </AuthProvider>
      </React.StrictMode>,
      document.getElementById('root')
    );
  } else {
    // Handle the case where the user is not authenticated, maybe redirect to a different page or show a message
    console.warn('Not authenticated!');
  }
}).catch((error) => {
  console.error('Keycloak initialization failed', error);
});
