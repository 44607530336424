// ProtectedWrapper.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import keycloak from './services/keycloak/keycloakService';  // or wherever you keep your instance

const ProtectedWrapper = ({ children }) => {
    if (!keycloak.authenticated) {
        return <Navigate to="/login" />;
    }

    return children;
};

export default ProtectedWrapper;
