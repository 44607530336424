import React, { useState, useEffect } from 'react';
import { fetchBalances, fetchPortfolio } from '../../services/portfolio/portfolioService.js'; // Import the service functions
import { GridContainer, GridItem, Container, Card, CardHeader, CardContent, TextSmall, TextSmallRed } from '../Styles/Styles';
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Link } from 'react-router-dom'; // Import the Link component
import styled, { keyframes } from 'styled-components';


const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

// Styled component for the Spinner
const Spinner = styled.div`
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 5px solid rgba(195, 195, 195, 0.6);
  border-radius: 50%;
  border-top-color: #636767;
  animation: ${spin} 1s ease-in-out infinite;
  -webkit-animation: ${spin} 1s ease-in-out infinite;
  margin: 20px auto;
  display: block;
`;



const PortfolioPage = () => {
  const [portfolio, setPortfolio] = useState(null);
  const [balanceHistory, setBalanceHistory] = useState([]);
  const [chartHeight, setChartHeight] = useState(300); // Default height
  const [isLoading, setIsLoading] = useState(true); // Loading state

  const updateChartHeight = () => {
    const chartContainer = document.getElementById('chartContainer');
    if (chartContainer) {
      const offsetTop = chartContainer.getBoundingClientRect().top;
      const viewportHeight = window.innerHeight;
      const footerHeight = viewportHeight * 0.10 - 13; // 10% of the viewport height
      const newHeight = viewportHeight - offsetTop - footerHeight;
      setChartHeight(newHeight > 0 ? newHeight : 300); // Minimum height fallback
    }
  };

  const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 85vh;
  background-color: #1A202C;
`;

  useEffect(() => {
    updateChartHeight();
    window.addEventListener('resize', updateChartHeight);

    return () => window.removeEventListener('resize', updateChartHeight);
  }, []);

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
  
      try {
        const portfolioData = await fetchPortfolio();
        setPortfolio(portfolioData);
  
        const balances = await fetchBalances();
        // Check if balances data is empty
        const balanceHistoryData = balances.length === 0 
          ? [{ date: 'Start', balance: 0 }] // If no data, start with initial balance
          : balances.map(balance => ({ date: balance.date, balance: balance.totalBalance })); // Use received balances data
        
        setBalanceHistory(balanceHistoryData);
        setIsLoading(false);
  
      } catch (error) {
        console.error('Error fetching portfolio and balance data:', error);
        setIsLoading(false);
        // Handle the error appropriately
      }
    };
  
    loadData();
  }, []);
  


  const pnlStyle = (value) => ({
    color: value >= 0 ? 'green' : 'red'
  });

  const totalProfit = balanceHistory.length > 1
  ? Math.round(balanceHistory[balanceHistory.length - 1].balance - balanceHistory[0].balance)
  : 0;



  if (isLoading) {
    return (
      <SpinnerContainer>

        <Spinner />
      </SpinnerContainer>

    );
  }

  return (
    <Container>
      <Card>
        <GridContainer>
          {/* Display balance */}
          <GridItem>
            <TextSmall>Balance</TextSmall>
            <p>{portfolio.balanceAmount} $</p>
          </GridItem>
          {/* Display realized and unrealized PNL */}
          <GridItem>
            <TextSmall>Realized PNL</TextSmall>
            <p style={pnlStyle(portfolio.realizedPNL)}>{portfolio.realizedPNL} $</p>
          </GridItem>
          <GridItem>
            <TextSmall>Unrealized PNL</TextSmall>
            <p style={pnlStyle(portfolio.unrealizedPNL)}>{portfolio.unrealizedPNL} $</p>
          </GridItem>
          {/* Display open positions */}
          <GridItem>
            <TextSmall>Open Positions</TextSmall>
            <Link to="/transactions" style={{ textDecoration: 'none', color: 'white' }}>
              <p style={{ fontWeight: 'bold' }}>{portfolio.numberOfOpenPositions}</p>
            </Link>
          </GridItem>
          {/* <GridItem>
            <TextSmall>Total Profit</TextSmall>
            <p style={pnlStyle(totalProfit)}>{totalProfit} $</p>
          </GridItem> */}
        </GridContainer>
        <GridItem>
          <TextSmall>Total Profit</TextSmall>
          <p style={pnlStyle(totalProfit)}>{totalProfit} $</p>
        </GridItem>
        <div id="chartContainer" style={{ width: '100%', marginTop: '20px' }}>
          <ResponsiveContainer width="100%" height={chartHeight}>
            <LineChart data={balanceHistory}>
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="balance" stroke="#8884d8" />
            </LineChart>
          </ResponsiveContainer>
        </div>

      </Card>
    </Container>
  );
};

export default PortfolioPage;
