import React, { useState, useContext, useEffect  } from 'react';
import { Disconnect, Connect } from '../../services/Ibkr/IbkrService';
import { ConnectionStatusContext } from '../../Context/ConnectionStatusContext';
import { SliderLabel,LabelText, Switch, Checkbox, Slider, CustomCard, CenteredContainer, Title, GridContainer, GridItem, Input, ConnectButton} from './styles';
import styled from 'styled-components';
import { Spinner, SpinnerWrapper } from './styles';


const BrokerConnectPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const { status, setStatus } = useContext(ConnectionStatusContext);
  const [isConnecting, setIsConnecting] = useState(false);
  const [paperTrading, setPaperTrading] = useState(false); // Add this line

  const handleLogin = async () => {
    try {
      setIsConnecting(true);
      await Connect(username, password, paperTrading); // Modified to include paperTrading
      setPassword(''); // Clear the password field
    } catch (error) {
      console.error('Login failed:', error);
      setIsConnecting(false);
      setStatus(null); // Update global status
    }
  };

  const handleDisconnect = async () => {
    try {
      await Disconnect();
      setIsConnecting(false);
      setStatus(null); // Update global status
    } catch (error) {
      console.error('Disconnect failed:', error);
      setIsConnecting(false);
      setStatus(null); // Update global status
    }
  };

  // const ConnectButtonContent = ({ status, isConnecting }) => {
  //   if (isConnecting) {
  //     return <>Connecting <SpinnerIcon /></>; // Replace 'SpinnerIcon' with your spinner component or element
  //   }
  //   if (status === 'healthy') {
  //     return 'Disconnect';
  //   }
  //   if (status === 'initializing' || status === 'waiting for 2fa') {
  //     return 'Cancel';
  //   }
  //   return 'Connect';
  // };
  useEffect(() => {
    // Whenever the status changes, check if it's 'Connected'
    // If it is, set isConnecting to false
    if (status === 'Connected') {
      setIsConnecting(false);
    }
  }, [status]);

  return (
    <CenteredContainer>
      <CustomCard>
        <Title>IBKR Connect</Title>
        <GridContainer>
        <GridItem>
            <SliderLabel>
              <LabelText>Paper Trading Mode</LabelText>
              <Switch>
                <Checkbox
                  checked={paperTrading}
                  onChange={(e) => setPaperTrading(e.target.checked)}
                />
                <Slider />
              </Switch>
            </SliderLabel>
          </GridItem>
          <GridItem>
            <Input
              type="text"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </GridItem>
          <GridItem>
            <Input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </GridItem>
          <GridItem>
          <ConnectButton onClick={isConnecting || status === 'Connected' ? handleDisconnect : handleLogin}>
            {isConnecting || status === 'Connecting..' ? (
              <SpinnerWrapper>
                <Spinner />
              </SpinnerWrapper>
            ) : status === 'Connected' ? 'Disconnect' : 'Connect'}
          </ConnectButton>
          </GridItem>
        </GridContainer>
        {status && <div className="status-message">{status}</div>}
      </CustomCard>
    </CenteredContainer>
  );
};

export default BrokerConnectPage;
