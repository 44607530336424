import React, { useEffect, useState } from 'react';
import { fetchPositions, fetchOrders } from '../../services/portfolio/portfolioService'
import { StyledTextSmall, Container, FlexRow, TabButton, Content, Card, TextSmall, CardContent, CardHeader, TextSmallRed } from '../Styles/Styles';

function OrdersPage() {
  const [activeTab, setActiveTab] = useState('Positions');
  const [positions, setPositions] = useState([]);
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const positionsData = await fetchPositions();
        setPositions(positionsData);
        const ordersData = await fetchOrders();
        setOrders(ordersData);
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    };

    fetchData();
  }, []);


  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  function formatDateWithoutYear(dateStr) {
    if (!dateStr) return '';

    // Split the date string into [year, month, day]
    const parts = dateStr.split('-');
    if (parts.length !== 3) return dateStr; // Return original if format is unexpected

    // Return the date in MM-DD format
    return `${parts[1]}-${parts[2]}`;
  }


  return (
    <Container>
      <FlexRow>
        <TabButton active={activeTab === 'Positions'} onClick={() => handleTabClick('Positions')}>
          Positions
        </TabButton>
        <TabButton active={activeTab === 'Orders'} onClick={() => handleTabClick('Orders')}>
          Orders
        </TabButton>

      </FlexRow>

      <Content>
        {activeTab === 'Orders' && (
          <div>
            <CardHeader>
              <TextSmall>Date</TextSmall>
              <TextSmall>Instrument</TextSmall>
              <TextSmall>Side</TextSmall>
              <TextSmall>Quantity</TextSmall>
              <TextSmall>Price</TextSmall>
            </CardHeader>
            {orders.map(order => (
              <Card key={order.uuid}>
                <CardContent>
                  <TextSmall>{formatDateWithoutYear(order.date)}</TextSmall>
                  <TextSmall>{order.instrument}</TextSmall>
                  <TextSmallRed>{order.side}</TextSmallRed>
                  <TextSmall>{order.quantity}</TextSmall>
                  <TextSmall>{Number(order.price).toFixed(2)}</TextSmall>
                </CardContent>
              </Card>
            ))}

          </div>
        )}
        {activeTab === 'Positions' && (
          <div>
            <CardHeader>
              <TextSmall>Instrument</TextSmall>
              <TextSmall>Quantity</TextSmall>
              <TextSmall>Bought Px</TextSmall>
            </CardHeader>
            {positions.map(position => (
              <Card key={position.instrument}>
                <CardContent>
                  <TextSmall>{position.instrument}</TextSmall>
                  <StyledTextSmall isPositive={position.quantity > 0}>
                    {position.quantity}
                  </StyledTextSmall>
                  <TextSmall>{Number(position.boughtPrice).toFixed(2)}</TextSmall>
                </CardContent>
              </Card>
            ))}

          </div>
        )}
      </Content>
    </Container>
  );
}

export default OrdersPage;
