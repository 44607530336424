import React from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import Logo from '../assets/ISWADO_LOGO.png'
const HeaderContainer = styled.div`
  padding: 16px;
  background-color: #2d3748;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Title = styled.h1`
  text-align: center;
  flex: 1;
  font-size: 1.125rem;
  font-weight: bold;
  color: #CBD5E0;
`;

const LogoStyle = styled.img`
    /* position: absolute; */
    flex: 1;
    height: 100%;

`

const IconButton = styled.button`
  background: none;
  border: none;
  outline: none;
  box-shadow: none;
  color: #CBD5E0; /* Light text color */
  padding: 8px; /* Slight padding around the icon */
  border-radius: 0; /* Removes any default border-radius */
  -webkit-appearance: none; /* Removes default browser styling on iOS */
  -moz-appearance: none; /* Removes default browser styling on Firefox */
  appearance: none; /* Removes default browser styling */

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: color 0.3s, background-color 0.3s;

  &:hover {
    color: #ffffff;
    background-color: #2d3748; /* Slight background color on hover */
  }

  &:active {
    background-color: #4a5568; /* Slightly darker background color on active/click */
  }

  &:focus-visible {
    outline: 2px solid #CBD5E0; /* Only show an outline when the element is focused by keyboard navigation */
  }

  &:last-child {
    margin-right: 0;
  }
`;
// Assuming you've already created a styled IconButton component
// ...
const PageTitle = ({ pathname }) => {
  switch (pathname) {
    case '/':
      return 'Main Page';
    case '/transactions':
      return 'Transactions';
    case '/portfolio':
      return 'Portfolio';
    case '/broker-connect':
      return 'IBKR Connection';
    // Add more cases as needed
    default:
      return 'Page Not Found'; // Fallback for no matching route
  }
};


const LogoContainer = styled.div`
  flex: 1;
  justify-content: center;
  height: 50px;
  width: 50px;
`;

const Header = () => {
  const location = useLocation();

  return (
    <HeaderContainer>
      <LogoContainer>
        <LogoStyle src={Logo} alt="Logo" />
      </LogoContainer>
      <Title>
        <PageTitle pathname={location.pathname} />
      </Title>
      <div style={{ flex: "1" }} />



      {/* <div>
          <IconButton>
            <SearchIcon />
          </IconButton>
          <IconButton>
            <MoreVertIcon />
          </IconButton>
        </div> */}
    </HeaderContainer>
  );
};

export default Header;
