// keycloak-service.js

import Keycloak from 'keycloak-js';
const keycloakConfig = {
  url: 'https://keycloak-prod.iswado.com',
  realm: 'iswado',
  clientId: 'iswado-client'
};

const keycloak = new Keycloak(keycloakConfig);

export const isAuthenticated = () => {
  try {
    return keycloak.authenticated && !keycloak.isTokenExpired();
  } catch (error) {
    console.error("Error validating authentication", error);
    return false;
  }
};

export const initKeycloak = () => {
  return keycloak.init({ onLoad: 'login-required' }).then(authenticated => {
    if (authenticated) {
      keycloak.onTokenExpired = () => {
        alert('Session expired. Logging out...');
        keycloak.logout();
      };

      // Add this periodic token check:
      setInterval(() => {
        keycloak.updateToken(60)
            .catch(() => {
                alert('Session expired. Logging out...');
                keycloak.logout();
            });
      }, 60000);
    }
    return authenticated; 
  });
};

export const refreshKeycloakToken = () => {
  return keycloak.updateToken(300).catch(() => {
    keycloak.logout();
  });
};

export default keycloak; // This allows you to use the keycloak instance directly if required
