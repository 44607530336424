// AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import keycloak from './services/keycloak/keycloakService';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(keycloak.authenticated);

    useEffect(() => {
        // You can add more complex checks or listeners here if required
        setIsAuthenticated(keycloak.authenticated);
    }, []);

    return (
        <AuthContext.Provider value={isAuthenticated}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
