import axios from 'axios';
import keycloak from '../keycloak/keycloakService';

const baseURL = process.env.REACT_APP_API_URL || 'http://52.19.95.24:80';

const api = axios.create({
  baseURL: baseURL,
});

api.interceptors.request.use(
  async (config) => {
    // Refresh token if it's expired
    if (keycloak.isTokenExpired()) {
      await keycloak.updateToken(30);
    }

    const token = keycloak.token;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const login = async (username, password) => {
    try {
        const response = await api.get('/docker-containers/');
        console.log(response.data)
        return response.data;
      } catch (error) {
        throw error;
      }
  };

export const Connect = async (userId, password, paperTrading) => {
    try {
      const url = `/docker-containers?password=${encodeURIComponent(password)}&userid=${encodeURIComponent(userId)}&paper_trading=${paperTrading}`;
      const response = await api.post(url);
      console.log(response.data);
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  
  

  
  export const Disconnect = async () => {
    try {
      const url = `/docker-containers`;
      const response = await api.delete(url);
      console.log(response.data)
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  export const checkContainerHealth = async () => {
    try {
        const response = await api.get('/docker-containers/health');
        console.log("Container health response:", response.data); // Log the entire response data
        return response.data; // Return the response data
    } catch (error) {
        console.error('Error checking container health:', error);
        throw error;
    }
  };
  