import styled, {keyframes} from 'styled-components';
import { Card, Container } from '../Styles/Styles';

export const CustomCard = styled(Card)`
  width: 400px;
  margin: 20px;
  text-align: center;
`;

// CenteredContainer (Apply background color here)
export const CenteredContainer = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
  background-color: #1A202C; /* Dark background */
  color: #CBD5E0; /* Light text color */
  font-family: 'Roboto', sans-serif;
  overflow-y: auto; /* Scroll inside container if content overflows */
`;

export const Title = styled.h3`
  font-size: 1.5rem;
  color: #CBD5E0;
  margin-bottom: 16px;
`;

export const GridContainer = styled.div`
  display: grid;
  grid-gap: 16px;
  justify-items: center;
`;

export const GridItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const Input = styled.input`
  width: 100%;
  padding: 8px;
  border: 1px solid #CBD5E0;
  border-radius: 4px;
  font-size: 1rem;
  outline: none;
  transition: border-color 0.3s;

  &:focus {
    border-color: #4a90e2;
  }

  &::placeholder {
    color: #a0aec0;
  }
`;

export const ConnectButton = styled.button`
  background-color: #4a90e2;
  color: #fff;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: #357abd;
  }
`;



/// slider checkbox

export const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
`;

export const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;

  &:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
  }
`;

export const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + ${Slider} {
    background-color: #2196F3;
  }

  &:focus + ${Slider} {
    box-shadow: 0 0 1px #2196F3;
  }

  &:checked + ${Slider}:before {
    transform: translateX(26px);
  }
`;

export const SliderLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px; // Adjust as needed
`;

export const LabelText = styled.span`
  margin-right: 10px; // Adjust as needed
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

// The Spinner component
export const Spinner = styled.div`
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: ${spin} 2s linear infinite;
`;

export const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
