// HealthCheckService.js

import { checkContainerHealth } from '../services/Ibkr/IbkrService';

export const checkHealth = async (updateStatus) => {
  try {
    const response = await checkContainerHealth();
    updateStatus(response.status);
  } catch (error) {
    console.error('Error checking container health:', error);
  }
};
